<template>
<div class="mainform">
  <div class="form">
    <div class="content">
        <el-form ref="form" class="disabled" :disabled="formDisabled">
          <div class="col col8" style="margin: 0px;" v-if="fieldShow.bidEndDate1!==false">
            <el-form-item label="应标截止日期" label-width="108px">
                  <el-date-picker disabled v-model="bidEndDate1" type="datetime" placeholder="应标截止日期"></el-date-picker>
            </el-form-item>
          </div>
          <div class="col col8" v-if="fieldShow.table!==false">
            <el-form-item label="报价" label-width="57px" :required="!formDisabled"></el-form-item>
            <div class="tableList">
                <div class="table">
                    <el-table border size=small class="table" :data="tableData" style="width: 100%">
                        <template :key="index" v-for="(item,index) in title">
                            <el-table-column v-if="item!='lineNumber'" :show-overflow-tooltip="true" :prop="item" :label="item" width="">
                                <template #default="scope">
                                    <div v-if="!scope.row[item].isRequired">{{scope.row[item].value}}</div>
                                    <div v-if="scope.row[item].isRequired">
                                        <el-input v-model="scope.row[item].value" placeholder="请输入"></el-input>
                                    </div>
                                </template>
                            </el-table-column>
                        </template>
                    </el-table>
                </div>
            </div>
          </div>
          <div class="col col8" v-if="fieldShow.paymentDays!==false">
            <el-form-item label="账期" label-width="57px" :required="!formDisabled">
                  <el-autocomplete v-model="quoteData.paymentDays" :fetch-suggestions="querySearch" placeholder="请输入内容"></el-autocomplete>
            </el-form-item>
          </div>
          <div class="col col8" v-if="fieldShow.srmFileList!==false">
            <el-form-item label="标书" :class="{isreadonly:formDisabled}" label-width="57px" :required="!formDisabled">
                  <el-upload
                      :action="actionURL"
                      :on-preview="handlePreview"
                      :on-remove="handleRemove"
                      :before-remove="beforeRemove"
                      :headers="tokenHeader"
                      :on-success="handleSuccess"
                      multiple
                      :on-exceed="handleExceed"
                      :file-list="quoteData.srmFileList"
                  >
                      <el-button v-if="!formDisabled" size="small" type="primary" plain>点击上传</el-button>
                  </el-upload>
              </el-form-item>
          </div>
        </el-form>
    </div>
  </div>
</div>
</template>

<script>
import { baseHost } from '@/assets/js/PublicData.js'
import { MIME } from '@/assets/js/mime.js'
import { exportForm } from '@/assets/js/http.js'
import { quoteData } from './js/config.js'
import { ref } from 'vue'

export default {
  name: 'QuotationBodyShow',
  props: ['quotationData', 'bidEndDate', 'dataFormat', 'remoteQuoteData'],
  data: function () {
    let DquoteData = quoteData
    if (this.remoteQuoteData) {
      DquoteData = this.remoteQuoteData
    } else {
      DquoteData = quoteData
    }
    return {
      title: this.quotationData.title,
      actionURL: baseHost.SupplierRegHost + '/api/file/m/file/uploadToServer',
      tokenHeader: { token: localStorage.getItem('token') },
      tableData: this.quotationData.list,
      restaurants: ref([]),
      quoteData: { ...DquoteData },
      formDisabled: false,
      fieldShow: {},
      bidEndDate1: this.bidEndDate
    }
  },
  mounted: function () {
    this.restaurants.value = [{ value: '30天' }, { value: '45天' }, { value: '60天' }, { value: '90天' }]
    if (this.dataFormat) {
      if (this.dataFormat.formDisabled) {
        this.formDisabled = true
      }
      if (this.dataFormat.fieldShow) {
        this.fieldShow = this.dataFormat.fieldShow
      }
    }
  },
  watch: {
    quotationData: function () {
      this.title = this.quotationData.title
      this.tableData = this.quotationData.list
    },
    bidEndDate: function () {
      this.bidEndDate1 = this.bidEndDate
    },
    remoteQuoteData () {
      this.quoteData = this.remoteQuoteData
    }
  },
  methods: {
    querySearch (queryString, cb) {
      var results = queryString ? this.restaurants.value.filter(this.createFilter(queryString)) : this.restaurants.value
      cb(results)
    },
    createFilter (queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        )
      }
    },
    accept: function () {
    },
    handleRemove (file) {
      for (var i = 0; i < this.quoteData.srmFileList.length; i++) {
        if (this.quoteData.srmFileList[i].id === file.id) {
          this.quoteData.srmFileList.splice(i, 1)
        }
      }
    },
    handlePreview (file) {
      exportForm('/api/file/m/file/downloadFile/' + file.id, 'get').then(
        (response) => {
          var fileExtension = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase()
          var mime = 'application/octet-stream'
          if (MIME[fileExtension]) {
            mime = MIME[fileExtension]
          }
          const aBlob = new Blob([response.data], { type: mime })
          this.downloadByBlob(aBlob, file.name, mime)
        })
    },
    handleExceed (files, fileList) {
      // this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`)
    },
    beforeRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    handleSuccess (response, file, fileList) {
      if (response.code === '200') {
        response.data.forEach(element => {
          this.quoteData.srmFileList.push(element)
        })
      }
    },
    downloadByBlob (data, fileName, mime) {
      try {
        const url = window.URL.createObjectURL(data)
        const eleLink = document.createElement('a')
        eleLink.href = url
        eleLink.target = '_blank'
        if (mime.indexOf('image') < 0 && mime.indexOf('pdf') < 0) {
          eleLink.download = fileName
        }
        eleLink.click()
        window.URL.revokeObjectURL(url)
      } catch (error) {
        console.error('download function error!', error)
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/elForm.scss';
@import '@/assets/css/tableList.scss';
.tableList{
  padding: 0;
  :deep(.table){
  .el-table td {
    font-size: 14px;
    .el-input{
      line-height: 34px;
      .is-disabled .el-input__inner {
          color: #606266;
      }
    }
    .el-input__inner {
      border: none;
      background: none;
      height: 29px;
      line-height: 29px
    }
    .el-button{
      padding: 3px 4px;
    }
  }
}
}
.mainform{
  background: none;
  .form{
    background: none;
    :deep(.el-form-item){
      margin-bottom: 0px;
    }
  }
}
</style>
